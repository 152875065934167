import React,{ useState,useEffect }  from "react"

function InformationTextBlock({ informationBlockLink, informationBlockLinkButtonText, informationBlockSubTitle, informationBlockText, informationBlockTitle, openInNewTab }){
  const pageUrl = typeof window !== 'undefined' ? window.location.pathname : '';
  const [url, setUrl] = useState(pageUrl)
  const [removeTemp, setRemoveTemp] = useState(false)

  const updateLoad=(e)=>{
    if(url==="/careers/career-opportunities/"){
      setRemoveTemp(true)
      //console.log("HELLO"+removeTemp)
    }
    else{
      //console.log("HELAT"+removeTemp)
      setRemoveTemp(false)
    }

  }
  useEffect(() => {
    // Run! Like go get some data from an API.
    updateLoad()
    //console.log(updateLoad)
  }, []);

return (
  <section className="content information-text-block">
   <div className="content-block clearfix">
   	<div className="eighty-spacer"></div>

    <div className="info-block-subtitle">{informationBlockSubTitle}</div>
						<div className="centered-title-holder" id="intro-block">
				<h3>{informationBlockTitle}</h3>
			</div>
						<div className="info-block-text" dangerouslySetInnerHTML={{ __html: informationBlockText }}/>
            {informationBlockLinkButtonText ?
							<div className="info-block-button">
					<a href={informationBlockLink} target="_blank" rel="noopener noreferrer">{informationBlockLinkButtonText}</a>
				</div>
        :
        null}
						<div className="one-hundred-spacer"></div>

	</div>
</section>
 )
  }


export default InformationTextBlock
