import React, { useState,useEffect } from "react"

function PageIntro({ pageColumnsColumn1,pageColumnsColumn2,halfLeftColumn,halfRightColumn }){
  const pageUrl = typeof window !== 'undefined' ? window.location.pathname : '';
  const [url, setUrl] = useState(pageUrl)
  const [removeTemp, setRemoveTemp] = useState(false)
  const [showHalf, setShowHalf] = useState(false)

  const updateLoad=(e)=>{
    if(url==="/intermediaries/" || url==="/specialist-car-finance-for-individuals/personal-product-range/" || url==="/intermediaries/specialist-car-finance-intermediaries/product-range/"){
      setRemoveTemp(true)
    }
    else{
      setRemoveTemp(false)
    }

    if(pageColumnsColumn1.pageIntroLeftTitle || pageColumnsColumn1.pageIntroLeftIntro){
        setShowHalf(true)
    }else{
      setShowHalf(false)
    }

  }
  useEffect(() => {
    updateLoad()
  }, []);


return (
  <section className="content navy-background">

  {showHalf ?
  <div className="content-block">
  <div className="full-width-flex-container">
     <div className="full-half-flex-container">
        <div className="eighty-spacer"></div>
        <div className={`${removeTemp ? 'sign-post-half-container staggerFadeInFirst first-load' : 'page-intro-half-container staggerFadeInFirst first-load'}`}>
           {pageColumnsColumn1.pageIntroLeftTitle ? (
             <h1>{pageColumnsColumn1.pageIntroLeftTitle}</h1>
             ) : null}
             {pageColumnsColumn1.pageIntroLeftIntro ? (
           <h2 dangerouslySetInnerHTML={{ __html: pageColumnsColumn1.pageIntroLeftIntro }}/>
           ) : null}
           {pageColumnsColumn1.pageIntroLeftText ? (
             <span dangerouslySetInnerHTML={{ __html: pageColumnsColumn1.pageIntroLeftText }}/>
             ) : null}
           {pageColumnsColumn1.pageIntroLeftButtonLink ? (
             <ul>
                <li><a href={pageColumnsColumn1.pageIntroLeftButtonLink} target="_blank" rel="noopener noreferrer">{pageColumnsColumn1.pageIntroLeftButtonLabel}</a></li>
             </ul>
             ) : null}
           <div className="eighty-spacer"></div>
        </div>
     </div>

     <div className="full-half-flex-container initial-cta-holder">
        <div className="eighty-spacer"></div>
        <div className="sign-post-half-container">
           <div className="staggerFadeInFirst first-load">
           {pageColumnsColumn2.pageIntroRightTitle ? (
             <h1>{pageColumnsColumn2.pageIntroRightTitle} </h1>
             ) : null}
            {pageColumnsColumn2.pageIntroRightIntro ? (
            <h2>  {pageColumnsColumn2.pageIntroRightIntro}</h2>
            ) : null}
            {pageColumnsColumn2.pageIntroRightText ? (
              <div dangerouslySetInnerHTML={{ __html: pageColumnsColumn2.pageIntroRightText }}/>
                ) : null}
               {pageColumnsColumn2.pageIntroRightButtonLink ? (
                 <ul>
                    <li><a className={pageColumnsColumn2?.pageIntroRightButtonColour ? pageColumnsColumn2?.pageIntroRightButtonColour : 'blue'} href={pageColumnsColumn2.pageIntroRightButtonLink} target="_blank" rel="noopener noreferrer">{pageColumnsColumn2.pageIntroRightButtonLabel}</a></li>
                 </ul>
                 ) : null}
                 </div>

           <div className="forty-spacer"></div>

           <div className="staggerFadeInFirst first-load">
                          </div>
        </div>

        <div className="eighty-spacer"></div>

     </div>
  </div>
  </div>
:

  <div className="full-width-flex-container">

  <div className="full-half-flex-container business-finance-bg-colour staggerFadeInFirst first-load">
  <div className="twenty-spacer"></div>
  <div className="page-login-cta-holder navy-text">
  <div className="">
  <h1 className="navy-text">
  {halfLeftColumn.halfLeftOneTitle} </h1>
  </div>
  <br/>
  <div className="twenty-spacer"></div>
  <div className="terms-link white-button navy-text sbs-button-one">
  <a href={halfLeftColumn.halfLeftOneLink}>
  {halfLeftColumn.halfLeftOneButtonText}</a>
  </div>
  <div className="featured-cta-content navy-text">
  <div className="thirty-spacer"></div>
  <div dangerouslySetInnerHTML={{ __html: halfLeftColumn.halfLeftOneText }}/>
  </div>
  <div className="twenty-spacer"></div>
  </div>
  </div>

  <div className="full-half-flex-container business-finance-bg-colour staggerFadeInFirst first-load">
  <div className="twenty-spacer"></div>
  <div className="page-login-cta-holder navy-text">
  <div className="">
  <h1 className="navy-text">
  {halfRightColumn.halfRightOneTitle} </h1>
  </div>
  <br/>
  <div className="twenty-spacer"></div>
  <div className="terms-link white-button navy-text sbs-button-one">
  <a href={halfRightColumn.halfRightOneLink}>
  {halfRightColumn.halfRightOneButtonText}</a>
  </div>
  <div className="featured-cta-content navy-text">
  <div className="thirty-spacer"></div>
  <div dangerouslySetInnerHTML={{ __html: halfRightColumn.halfRightOneText }}/>
  </div>
  <div className="twenty-spacer"></div>
  </div>
  </div>

  </div>
}

</section>
 )
  }


export default PageIntro
