
// This is a temporary generated file. Changes to this file will be overwritten eventually!
import React, {useState,useEffect } from "react"
import Link from "gatsby-link"
import Header from "../src/components/Header"
import Layout from "../src/components/Layout"
import SEO from "../src/components/SEO"
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { decode } from 'he'


// Sections
import PageIntro from '../src/layouts/PageIntro';
import TileSystemForSingle from '../src/layouts/TileSystemForSingle';
import InformationTextBlock from '../src/layouts/InformationTextBlock';
import LatestNewsInsights from '../src/layouts/LatestNewsInsights';

const Page = ({ pageContext,location  }) => {
  const {
    page: { title, content, dynamicContent,pageId,heroImage,stickyCtaBanner,seo},
    breadcrumb: { crumbs},
  } = pageContext

  const layouts = dynamicContent.layouts || []
  const [hideOnScroll, setHideOnScroll] = useState(true)
  const [removeTemp, setRemoveTemp] = useState(false)
  const pageUrl = typeof window !== 'undefined' ? window.location.pathname : '';
  const [url, setUrl] = useState(pageUrl)
  const [showFilter,setShowFilter] = useState(false)
  const isPartiallyActive = ({ isPartiallyCurrent, isCurrent }) => {
    return isPartiallyCurrent && isCurrent
      ? { className: 'breadcrumb__link breadcrumb__link__active' }
      : {}
  }

  function formatName(pageId) {
    return 'wrapper page-' + pageId;
  }
  function breadCrumbClass(){
    if(dynamicContent.layouts[0].fieldGroupName=="page_Dynamiccontent_Layouts_HeroBanner"){
      return 'content ';
    }
    else{
      return 'content no-image-breadcrumb';
    }
  }

  const newCrumbs = crumbs.map(item => {
    //console.log(item)
    const container = {};
    container.crumbLabel = decode(item.crumbLabel.replace(/-/g, ' '));
    container.pathname = item.pathname;

    return container;
  })

  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = currPos.y
    if(currPos.y>-1) {
      setRemoveTemp(false)
    }
    else if(currPos.y<-800) {
      if(url=="/personal-savings/" || url=="/business/savings/" || url=="/business/development-finance-business/" || url=="/business/specialist-business-finance/" || url=="/intermediaries/specialist-business-finance-intermediaries/" || url=="/intermediaries/specialist-mortgages/" || url=="/intermediaries/specialist-mortgages/products/specialist-buy-to-let/" || url=="/intermediaries/specialist-mortgages/products/residential-commercial-bridging/" || url=="/business/savings/sme-savings-accounts/" || url=="/business/savings/charity-savings/"){
        setRemoveTemp(true)
      }
    }

  }, [hideOnScroll])

  return (
    <>
    <Header siteTitle={title} />
    <SEO title={seo.title} description={seo.metaDesc}/>
    <main className={formatName(pageId)}>
    {stickyCtaBanner.stickyCtaText ? (
    <div className={removeTemp ? 'sticky-cta visible' : 'sticky-cta'}>
    <div className="sticky-cta_title">{stickyCtaBanner.stickyPageSection} </div>
    <div  className="sticky-cta_para"  dangerouslySetInnerHTML={{ __html: stickyCtaBanner.stickyCtaText }}/>
      <div className="sticky-cta_btn-cont">
        <a href={stickyCtaBanner.stickyCtaLink}>
        <div className="sticky-cta_btn">
        {stickyCtaBanner.stickyCtaButtonText} </div>
        </a>
      </div>
    </div>
  )
  : null}
    <section className={breadCrumbClass()}>
    {crumbs ? (
    <div className="breadcrumbs">
    <Breadcrumb
            crumbs={newCrumbs}
            crumbSeparator=" / "
            crumbLabel={decode(title)}
            getProps={isPartiallyActive}
          />

    </div>
    )
    : null}
    </section>
    <Layout>
      {
        layouts.map((layout, index) => {
          
              if (layout.fieldGroupName === 'page_Dynamiccontent_Layouts_PageIntro') {
                  return <PageIntro {...layout} key={index} />;
              }
            

              if (layout.fieldGroupName === 'page_Dynamiccontent_Layouts_TileSystemForSingle') {
                  return <TileSystemForSingle {...layout} key={index} />;
              }
            

              if (layout.fieldGroupName === 'page_Dynamiccontent_Layouts_InformationTextBlock') {
                  return <InformationTextBlock {...layout} key={index} />;
              }
            

              if (layout.fieldGroupName === 'page_Dynamiccontent_Layouts_LatestNewsInsights') {
                  return <LatestNewsInsights {...layout} key={index} />;
              }
            
        })
      }

    </Layout>
    </main>
    </>
  )
}

export default Page
  